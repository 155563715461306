<template>
    <div class="service-wrap page-fade-in">
        <div class="banner">
        </div>
        <div class="list-inner-wrap">
            <el-tabs :tab-position="tabPosition" id="tabs">
                <el-tab-pane :key="item.id" :label='item.title' v-for="item in list">
                    <div class="list-item-wrap">
                        <div class="text" v-html="item.content">
                        </div>
                        <!--<div class="image">
                            <img :src='getPath(item.litpic)' alt="">
                        </div>-->
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="m-service">
            <el-collapse accordion v-model="activeName">
                <el-collapse-item :key="item.id" :name='item.id' v-for="item in list">
                    <template slot="title">
                        {{ item.title }}
                    </template>
                    <div class="text" v-html="item.content">
                    </div>
                    <!--                    <div class="image">-->
                    <!--                        <img :src='getPath(item.litpic)' alt="">-->
                    <!--                    </div>-->
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import marked from "marked";

    export default {
        name: "service",
        data() {
            return {
                activeName: '30',
                tabPosition: 'left',
                list: []
            };
        },
        created: function () {
            //为了在内部函数能使用外部函数的this对象，要给它赋值了一个名叫self的变量。
            let self = this;
            $.ajax({
                url: 'http://licheng.xin/admin/news/getSolutions',
                type: 'get',
                data: {},
                dataType: 'json'
            }).then(function (res) {
                //把从json获取的数据赋值给数组
                for (let i = 0; i < res.length; i++) {
                    res[i].content = marked(res[i].content, {sanitize: true}).replace(/src="/, 'src="http://licheng.xin');
                    //console.log(res[i].content)
                }
                //console.log(res)
                self.list = res;
            }).fail(function () {
                console.log('获取信息失败');
            })
        },
        methods: {
            getPath(imgUrl) {
                return process.env.VUE_APP_HOST_URL + imgUrl;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import url('../assets/css/fonts.css');
    
    a {
        text-decoration: none;
        color: #999999;
    }
    
    .active {
        color: #2BA8FF;
        border-right: 1px solid #2BA8FF;
    }
    
    .service-wrap {
        min-height: 100vh;
        width: 100%;
        background-color: #ffffff;
        
        .banner {
            background: url("../assets/image/service_banner.png") center;
            background-size: cover;
            height: 700px;
        }
        
        .list-inner-wrap {
            width: 1200px;
            margin: 0 auto;
            padding: 100px 0;
            display: flex;
            justify-content: space-between;
            
            .list-item-wrap {
                /deep/ p {
                    margin-top: 0;
                    margin-bottom: 0;
                    text-align: justify;
                    line-height: 24px;
                    font-size: 16px;
                    color: #666666;
                }
                
                .image img {
                    width: 100%;
                    margin-top: 24px;
                    overflow: hidden;
                }
                
                /deep/ img {
                    width: 100%;
                    margin-top: 24px;
                    overflow: hidden;
                }
            }
        }
        
        .m-service {
            display: none;
        }
    }
    
    @media only screen and (max-width: 767px) {
        .service-wrap {
            .banner {
                height: 350px;
                background-position: center;
            }
            
            .list-inner-wrap {
                display: none;
            }
            
            .m-service {
                display: block;
                padding: 0 16px 24px;
                
                .text /deep/ p {
                    text-align: justify;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }
</style>

<style lang="scss">
    #tabs .el-tabs__nav-wrap::after {
        background-color: transparent;
    }
    
    #tabs .el-tabs__item.is-left {
        text-align: center;
    }
    
    #tabs .el-tabs__item {
        display: block;
        position: relative;
        padding: 20px 25px;
        height: auto;
        /*width: 240px;*/
        font-size: 1.58rem;
        color: #999999;
        outline: transparent;
        background: #F5F5F5;
        transition: .3s all ease-in-out;
    }
    
    #tabs .el-tabs__item:hover,
    #tabs .el-tabs__item.is-active {
        color: #2BA8FF;
    }
    
    #tabs .el-tabs__content {
        .list-item-wrap {
            padding-left: 80px;
            
            p.text {
                margin-top: 0;
                margin-bottom: 0;
                text-align: justify;
                line-height: 24px;
                font-size: 16px;
                color: #666666;
            }
            
            .image {
                width: 100%;
                margin-top: 24px;
                overflow: hidden;
                
                img {
                    width: inherit;
                }
            }
        }
    }
</style>
